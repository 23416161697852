<template>
  <div>
    <b-card no-body>
      <template #header>
        <h4 class="mr-auto mb-0">
          {{ tableTitle }}
        </h4>

        <b-button @click="openOnboardNewMerchantModal">
          Onboard New Merchant
        </b-button>
      </template>
      <div>
        <b-row>
          <b-col
            cols="12"
            lg="4"
          >
            <div class="d-flex px-2 py-1">
              <b-button
                size="sm"
                class="mr-1"
                @click="refreshTable"
              >
                Refresh
              </b-button>
              <b-form-select
                v-model="payloadConfig.perPage"
                class="w-25"
                :options="perPageOptions"
                size="sm"
                @change="updatePerPage"
              />
            </div>
          </b-col>

          <b-col
            cols="12"
            lg="4"
          >
            <div class="d-flex px-2 py-1">

              <flat-pickr
                v-model="tempAdvForm.created_at"
                placeholder="Select Date"
                class="form-control form-control-sm"
                :config="{ mode: 'range',
                           maxDate: new Date().fp_incr(1),
                           dateFormat: 'd-m-Y',}"
                @on-change="function(selectedDates , dateStr , instance ) {
                  onDateRangePickerSelected(selectedDates, dateStr, instance, 'created_at')
                }"
              />

            </div>
          </b-col>
          <b-col
            cols="12"
            lg="4"
          >

            <div class="d-flex px-2 py-1">
              <b-form-input
                v-model="payloadConfig.searchTerm"
                size="sm"
                placeholder="Search"
              />

              <b-button
                size="sm"
                class="mr-1"
                @click="updateTable(payloadConfig)"
              >
                Search
              </b-button>
            </div>
          </b-col>
        </b-row>
        <div class="d-flex px-2 py-1 border-top">
          <b-button
            size="sm"
            :variant="payloadConfig.onboardingSubmissionStateId === null ? 'primary' : 'secondary'"
            class="mr-1"
            @click="filterOnboardingSubmissionStatus()"
          >
            All
          </b-button>
          <b-button
            size="sm"
            :variant="payloadConfig.onboardingSubmissionStateId === 1 ? 'primary' : 'secondary'"
            class="mr-1"
            @click="filterOnboardingSubmissionStatus(1)"
          >
            Pending
          </b-button>

          <b-button
            :variant="payloadConfig.onboardingSubmissionStateId === 2 ? 'primary' : 'secondary'"
            size="sm"
            class="mr-1"
            @click="filterOnboardingSubmissionStatus(2)"
          >
            Pending Approval
          </b-button>
          <b-button
            :variant="payloadConfig.onboardingSubmissionStateId === 3 ? 'primary' : 'secondary'"
            size="sm"
            class="mr-1"
            @click="filterOnboardingSubmissionStatus(3)"
          >
            Approved Detail
          </b-button>
          <b-button
            :variant="payloadConfig.onboardingSubmissionStateId === 4 ? 'primary' : 'secondary'"
            size="sm"
            class="mr-1"
            @click="filterOnboardingSubmissionStatus(4)"
          >
            Rejected Detail
          </b-button>
          <b-button
            :variant="payloadConfig.onboardingSubmissionStateId === 5 ? 'primary' : 'secondary'"
            size="sm"
            class="mr-1"
            @click="filterOnboardingSubmissionStatus(5)"
          >
            Verify Agreement
          </b-button>
          <b-button
            :variant="payloadConfig.onboardingSubmissionStateId === 6 ? 'primary' : 'secondary'"
            size="sm"
            class="mr-1"
            @click="filterOnboardingSubmissionStatus(6)"
          >
            Approved Agreement
          </b-button>
          <b-button
            :variant="payloadConfig.onboardingSubmissionStateId === 7 ? 'primary' : 'secondary'"
            size="sm"
            class="mr-1"
            @click="filterOnboardingSubmissionStatus(7)"
          >
            Rejected Agreement
          </b-button>
          <b-button
            :variant="payloadConfig.onboardingSubmissionStateId === 8 ? 'primary' : 'secondary'"
            size="sm"
            class="mr-1"
            @click="filterOnboardingSubmissionStatus(8)"
          >
            Activated Account
          </b-button>
        </div>
        <b-table
          hover
          responsive
          :fields="TableHeader"
          :per-page="payloadConfig.perPage"
          :items="ListOfRegisteredMerchant"
          show-empty
        >
          <template #empty="scope">
            <div class="p-3 text-center">
              No records
            </div>
          </template>
          <template #emptyfiltered="scope">
            <div class="p-3 text-center">
              No records matching filter
            </div>
          </template>
          <template #cell(show_details)="row">
            <b-button
              size="sm"
              variant="link"
              class="p-0"
              @click="row.toggleDetails"
            >

              <template v-if="row.detailsShowing">
                <feather-icon
                  size="24"
                  icon="ChevronDownIcon"
                />
              </template>
              <template v-else>
                <feather-icon
                  size="24"
                  icon="ChevronRightIcon"
                />
              </template>
            </b-button>
          </template>

          <template #cell(action)="row">
            <b-button
              v-if="row.item.onboarding_submission_state_id !==2 && row.item.onboarding_submission_state_id !== 5"
              size="sm"
              @click="openEditSubmissionForm(row.item)"
            >
              View
            </b-button>
            <b-button
              size="sm"
              @click="getMerchantSandboxAccount(row.item)"
            >
              Sandbox
            </b-button>
            <b-dropdown
              v-if="row.item.onboarding_submission_state_id === 2"
              split
              size="sm"
              text="View"
              @click="openEditSubmissionForm(row.item)"
            >
              <b-dropdown-item
                :disabled="row.item.onboarding_submission_state_id !== 2"
                @click="approveSubmission(row.item.merchant_detail_id)"
              >
                Approve Detail
              </b-dropdown-item>
              <b-dropdown-item
                :disabled="row.item.onboarding_submission_state_id !== 2"
                @click="openRejectModal(row.item, 'details')"
              >
                Reject Detail
              </b-dropdown-item>
            </b-dropdown>
            <b-dropdown
              v-if="row.item.onboarding_submission_state_id === 5"
              split
              size="sm"
              text="View"
              @click="openEditSubmissionForm(row.item)"
            >
              <b-dropdown-item
                @click="approveMerchantAgreement(row.item.merchant_detail_id)"
              >
                Approve Agreement
              </b-dropdown-item>
              <b-dropdown-item
                @click="openRejectModal(row.item, 'agreement')"
              >
                Reject Agreement
              </b-dropdown-item>
            </b-dropdown>

          </template>

          <template #row-details="row">

            <b-card>
              <template
                v-for="(item, idx) in row.item.onboardingChecklist"
              >
                <b-row
                  :key="`onboarding-checklist-${idx}`"
                  class="mb-2"
                >
                  <b-col>{{ item.onboarding_checklist }}</b-col>
                  <b-col>{{ item.onboarding_checklist_status }}</b-col>
                  <b-col>{{ transformDate(item.updated_at, false, 'FF' ) }}</b-col>

                </b-row>
              </template>

            </b-card>
            <b-card>
              <b-row class="mb-2">
                <b-col
                  cols="3"
                  class="text-right"
                >
                  Created At
                </b-col>
                <b-col>{{ transformDate(row.item.created_at, false, 'FF' ) }}</b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col
                  cols="3"
                  class="text-right"
                >
                  Updated At
                </b-col>
                <b-col>{{ transformDate(row.item.updated_at, false, 'FF' ) }}</b-col>
              </b-row>
              <b-button
                size="sm"
                class="mr-2"
                @click="row.toggleDetails"
              >
                Hide Details
              </b-button>
              <b-button
                size="sm"
                @click="showRowDataModal(row.item)"
              >
                View JSON
              </b-button>
            </b-card>
          </template>
          <template #cell(created_at)="row">
            {{ transformDate(row.item.created_at, false, 'FF' ) }}
          </template>
          <template #cell(onboarding_submission_state)="row">
            <template v-if="row.item.onboarding_submission_state_id === 1">
              <b-badge variant="warning">
                Pending Submission
              </b-badge>
            </template>
            <template v-else-if="row.item.onboarding_submission_state_id === 2">
              <b-badge variant="info">
                Pending Approval
              </b-badge>
            </template>
            <template v-else-if="row.item.onboarding_submission_state_id === 3">
              <b-badge variant="success">
                Approved
              </b-badge>
            </template>
            <template v-else-if="row.item.onboarding_submission_state_id === 4">
              <b-badge variant="danger">
                Rejected
              </b-badge>
            </template>
            <template v-else-if="row.item.onboarding_submission_state_id === 5">
              <b-badge variant="info">
                Verify Agreement
              </b-badge>
            </template>
            <template v-else-if="row.item.onboarding_submission_state_id === 6">
              <b-badge variant="success">
                Approved Agreement
              </b-badge>
            </template>
            <template v-else-if="row.item.onboarding_submission_state_id === 7">
              <b-badge variant="danger">
                Rejected Agreement
              </b-badge>
            </template>
            <template v-else-if="row.item.onboarding_submission_state_id === 8">
              <b-badge variant="success">
                Activated Account
              </b-badge>
            </template>
            <template v-else>
              <b-badge>
                {{ row.item.onboarding_submission_state }}
              </b-badge>
            </template>
          </template>
        </b-table>

        <!--    <pre>{{ ListOfRegisteredMerchant }}</pre>-->

        <div class="d-flex justify-content-between align-items-center border-top p-2">
          <div>
            Total Records: {{ ListOfRegisteredMerchantMeta.recordsTotal }}
          </div>
          <b-pagination
            v-model="payloadConfig.currentPage"
            first-number
            last-number
            :disabled="ListOfRegisteredMerchantMeta.recordsTotal < payloadConfig.perPage"
            :total-rows="ListOfRegisteredMerchantMeta.recordsTotal"
            :per-page="payloadConfig.perPage"
            @change="pagination($event)"
          />
        </div>
      </div></b-card>
    <b-modal
      ref="onboardDetailViewRef"
      size="xl"
      header-bg-variant="primary"
      header-text-variant="light"
      title="Onboarding Detail Info"
      no-close-on-esc
      no-close-on-backdrop
      @shown="onDetailShown"
    >
      <onboard-detail-view
        ref="onboardDetailViewFormRef"
        :row-data="selectedRowData"
        @refresh-table="refreshTable"
      />
      <template #modal-footer="{cancel}">

        <button @click="toggleCheckBankModal">
          toggle
        </button>
        <b-button
          v-if="selectedRowData.onboarding_submission_state_id === 2"
          variant="danger"
          @click="openRejectModal(selectedRowData, 'details')"
        >
          Reject Detail
        </b-button>
        <b-button
          v-if="selectedRowData.onboarding_submission_state_id === 2"
          variant="primary"
          @click="approveSubmission(selectedRowData.merchant_detail_id)"
        >
          Approve Detail
        </b-button>
        <b-button
          v-if="selectedRowData.onboarding_submission_state_id === 5"
          variant="danger"
          @click="openRejectModal(selectedRowData, 'agreement')"
        >
          Reject Agreement
        </b-button>
        <b-button
          v-if="selectedRowData.onboarding_submission_state_id === 5"
          variant="primary"
          @click="approveMerchantAgreement(selectedRowData.merchant_detail_id)"
        >
          Approve Agreement
        </b-button>
        <b-button @click="cancel">
          Close
        </b-button>

      </template>
    </b-modal>
    <b-modal
      ref="onboardSandboxAccountViewer"
      size="md"
      header-bg-variant="primary"
      header-text-variant="light"
      title="Sandbox"
      hide-header
      no-close-on-esc
      no-close-on-backdrop
    >
      GetSandboxAccountForAdmin <br>
      <pre>{{ merchantSandboxAccount }}</pre>

      registered_sandbox_account <br>
      <pre>{{ regSandboxAccount }}</pre>

      sandbox_registration_data <br>
      <pre>{{ sandboxRegData }}</pre>
    </b-modal>
    <b-modal
      ref="onboardRowDataViewerRef"
      size="xl"
      header-bg-variant="primary"
      header-text-variant="light"
      title="Raw Data"
      hide-header
      no-close-on-esc
      no-close-on-backdrop
    >
      <json-viewer
        :value="selectedRowData"
        :expand-depth="5"
        copyable
        boxed
        sort
      />
    </b-modal>
    <b-modal
      ref="onboardNewMerchant"
      size="md"
      header-bg-variant="primary"
      header-text-variant="light"
      title="Onboard New Merchant"
      no-close-on-esc
      no-close-on-backdrop
      @ok.prevent="registerNewMerchant"
    >
      <onboard-new-merchant-form
        ref="onboardNewMerchantFormRef"
        @register-success="onRegisterNewMerchant"
      />

    </b-modal>

    <b-modal
      ref="onboardRejectModal"
      header-bg-variant="primary"
      header-text-variant="light"
      title="Reject Submission"
      size="md"
      ok-title="Reject Submission"
      no-close-on-esc
      no-close-on-backdrop
      @ok="rejectSubmission(selectedRowData, rejectSection)"
      @cancel="resetRejectSubmission"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group>
            <label>Reject reason</label>
            <b-form-textarea
              v-model="rejectForm.reason"
              size="sm"
              placeholder="Reject reason"
            />
          </b-form-group>

        </b-col>
        <b-col cols="12">
          <b-form-group>
            <label>Reject Section</label>

            <b-form-checkbox-group
              v-model="rejectForm.rejected_onboarding_checklists"
              class="rejection-reason-checkbox"
              :options="rejectOnboardingChecklistsOpts"
              name="rejectSection"
            />

          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </div>

</template>

<script>
import JsonViewer from 'vue-json-viewer'
import flatPickr from 'vue-flatpickr-component'
import onboardDetailView from '@/views/administration/onboardingv2/components/OnboardDetailView.vue'
import humaniseMixin from '@/common/humanise.mixin'
import OnboardNewMerchantForm from '@/views/administration/onboardingv2/components/OnboardNewMerchantForm.vue'

export default {
  components: {
    OnboardNewMerchantForm,
    onboardDetailView,
    JsonViewer,
    flatPickr,
  },
  mixins: [humaniseMixin],
  data() {
    return {
      merchantSandboxAccount: null,
      sandboxRegData: null,
      regSandboxAccount: null,
      tableTitle: 'Pending Onboarding List',
      dtForm: {
        created_at: {
          start: '',
          end: '',
        },
      },
      tempAdvForm: {
        created_at: '',
      },

      rangeDate: null,
      rejectForm: {
        reason: '',
        rejected_onboarding_checklists: [],
      },
      selectedRowData: {},
      rejectSection: 'details',
      payloadConfig: {
        perPage: 50,
        length: 50,
        currentPage: 1,
        searchTerm: '',
        onboardingStatusId: 1,
        onboardingSubmissionStateId: null,
        start: 0,
      },
    }
  },
  computed: {
    TableHeader() {
      const x = [
        {
          key: 'show_details',
          label: '',
          sortable: false,
          visible: true,
        },
        {
          key: 'onboarding_origin_reference_id', label: 'Origin', sortable: false, visible: false,
        },
        {
          key: 'company_name', label: 'Company Name', sortable: false, visible: true,
        },
        {
          key: 'referrer_merchant_id', label: 'Referrer', sortable: false, visible: true,
        },
        {
          key: 'full_name', label: 'Full Name', sortable: false, visible: true,
        },
        {
          key: 'email', label: 'Email', sortable: false, visible: true,
        },
        {
          key: 'phone_number', label: 'Phone Number', sortable: false, visible: true,
        },
        {
          key: 'onboarding_state', label: 'Status', sortable: false, visible: false,
        },
        {
          key: 'onboarding_submission_state', label: 'Submission Status', sortable: false, visible: true,
        },
        {
          key: 'onboarding_submission_state_id', label: 'Submission Status ID', sortable: false, visible: false,
        },

        {
          key: 'created_at', label: 'Created At', sortable: false, visible: true,
        },
        {
          key: 'action',
          label: 'action',
          class: 'text-right',
          sortable: false,
          visible: true,
        },
      ]

      if (this.payloadConfig.onboardingSubmissionStateId === 8) {
        const newItem = {
          key: 'created_at', label: 'Approved At', sortable: false, visible: true,
        }
        const idx = x.findIndex(item => item.key === 'created_at')
        x.splice(idx, 0, newItem)
      }

      return x.filter(y => y.visible)
    },

    perPageOptions() {
      return [
        { value: 5, text: '5' },
        { value: 10, text: '10' },
        { value: 25, text: '25' },
        { value: 50, text: '50' },
        { value: 100, text: '100' },
      ]
    },

    rejectOnboardingChecklistsOpts() {
      const list = this.selectedRowData.onboardingChecklist

      const submissionStateId = this.selectedRowData.onboarding_submission_state_id

      if (Array.isArray(list) && list.length > 0) {
        let filtered
        if (submissionStateId === 2) {
          filtered = list.filter(x => x.onboarding_checklist_id === 1 || x.onboarding_checklist_id === 2 || x.onboarding_checklist_id === 3 || x.onboarding_checklist_id === 4 || x.onboarding_checklist_id === 5 || x.onboarding_checklist_id === 6 || x.onboarding_checklist_id === 7)
          // filtered = list.filter(x => x.onboarding_checklist_id === 1)
        }
        if (submissionStateId === 5) {
          filtered = list.filter(x => x.onboarding_checklist_id === 8 || x.onboarding_checklist_id === 9)
        }
        if (Array.isArray(filtered) && list.length > 0) {
          return filtered.map(item => ({
            text: item.onboarding_checklist,
            value: item.onboarding_checklist_id,
          }))
        } return []
      }
      return []
    },
    ListOfRegisteredMerchant() {
      return this.$store.getters['onboardingv2/getPortalOnboardingListLocal']
    },
    ListOfRegisteredMerchantMeta() {
      const list = this.$store.getters['onboardingv2/getPortalOnboardingListLocalMeta']

      if (list) {
        const picked = (({ a, c }) => ({ a, c }))(list)
        return list
      }
      return {}
    },
  },
  async mounted() {
    const breadcrumbUpdatePayload = [
      { title: 'Local Onboarding' },
    ]
    await this.$store.dispatch('systemConstants/getSystemConstants')
    await this.$store.dispatch('onboardingv2/getOnboardConstants')
    await this.$store.dispatch('onboardingv2/getPayoutBankChannelList')
    await this.$store.dispatch('breadcrumbs/setBreadcrumb', breadcrumbUpdatePayload)
    this.payloadConfig.onboardingStatusId = 1
    await this.updateTable(this.payloadConfig)
  },
  methods: {
    onDetailShown() {
      this.$refs.onboardDetailViewFormRef.processOfferingsList()
    },
    async pagination(e) {
      console.log(e)
      this.payloadConfig.start = (e - 1) * this.payloadConfig.perPage
      await this.updateTable(this.payloadConfig)
    },

    onDateRangePickerSelected(selectedDates, dateStr, instance, fieldName) {
      if (selectedDates.length === 2) {
        instance.formatDate(selectedDates[0], 'Y-m-d')

        this.dtForm[fieldName].start = instance.formatDate(selectedDates[0], 'Y-m-d')
        this.dtForm[fieldName].end = instance.formatDate(selectedDates[1], 'Y-m-d')

        this.updateTable(this.payloadConfig)
      }
    },

    async toggleCheckBankModal() {
      const statusStore = await this.$store.getters['systemConstants/getCheckBankAccountModalStatus']

      const newStatus = !statusStore
      await this.$store.dispatch('systemConstants/toggleCheckBankAccountModal', newStatus)
    },

    async refreshTable() {
      this.payloadConfig.start = 0
      this.payloadConfig.currentPage = 1
      await this.updateTable(this.payloadConfig)
    },
    async updatePerPage(perPageVal) {
      this.payloadConfig.length = perPageVal

      this.payloadConfig.start = 0
      this.payloadConfig.currentPage = 1

      console.log(this.payloadConfig)
      await this.updateTable(this.payloadConfig)
    },
    async updateTable(config) {
      const filterValue = [

      ]

      if (this.dtForm.created_at.start !== '') {
        const item = {
          filter_column: 'created_at',
          filter_start: this.dtForm.created_at.start,
          filter_end: this.dtForm.created_at.end,
        }

        filterValue.push(item)
      }

      if (this.payloadConfig.onboardingSubmissionStateId) {
        const item = {
          filter_column: 'onboarding_submission_state_id',
          filter_value: this.payloadConfig.onboardingSubmissionStateId,
        }

        filterValue.push(item)
      }

      if (this.payloadConfig.searchTerm) {
        const item = {
          filter_column: 'keyword',
          filter_value: this.payloadConfig.searchTerm,
        }

        filterValue.push(item)
      }

      const payload = {
        length: config.length,
        start: config.start,
        perPage: config.perPage,
        filter_array_objects: JSON.stringify(filterValue),
      }

      await this.$store.dispatch('onboardingv2/getListOfRegisteredMerchants', payload)
    },
    openEditSubmissionForm(data) {
      this.selectedRowData = data
      this.$refs.onboardDetailViewRef.show()
    },
    openRejectModal(data, rejectSection) {
      console.log('data', data)
      this.rejectSection = rejectSection
      this.selectedRowData = data
      this.$refs.onboardRejectModal.show()
    },
    openOnboardNewMerchantModal() {
      this.$refs.onboardNewMerchant.show()
    },
    showRowDataModal(data) {
      this.selectedRowData = data
      this.$refs.onboardRowDataViewerRef.show()
    },
    getMerchantSandboxAccount(rowData) {
      this.sandboxRegData = rowData.sandbox_registration_data
      this.regSandboxAccount = rowData.registered_sandbox_account

      const payload2 = {
        merchant_detail_id: rowData.merchant_detail_id,
      }

      this.$store.dispatch('onboardingv2/getSandboxAccountForAdmin', payload2).then(resp => {
        // if (resp.data.response_code === 2100) {
        //   this.merchantSandboxAccount = resp.data.data
        //   this.$refs.onboardSandboxAccountViewer.show()
        // } else {
        //   this.$swal.fire({
        //     title: `Failed ${resp.data.response_code}`,
        //     text: resp.data.description,
        //     icon: 'error',
        //   })
        // }
        this.merchantSandboxAccount = resp.data
        this.$refs.onboardSandboxAccountViewer.show()
      })
    },
    async filterOnboardingStatus(statusId) {
      this.payloadConfig.onboardingStatusId = statusId
      if (statusId === 1) {
        this.tableTitle = 'Pending Onboarding List'
      }
      if (statusId === 2) {
        this.tableTitle = 'Approved Onboarding List'
      }
      if (statusId === 3) {
        this.tableTitle = 'Rejected Onboarding List'
      }
      await this.updateTable(this.payloadConfig)
    },
    async filterOnboardingSubmissionStatus(statusId = null) {
      this.payloadConfig.start = 0
      this.payloadConfig.onboardingSubmissionStateId = statusId

      if (!statusId) {
        this.payloadConfig.onboardingSubmissionStateId = null
      }
      await this.updateTable(this.payloadConfig)
    },
    async approveSubmission(merchantDetailId) {
      await this.$store.dispatch('onboardingv2/onboardingApproveMerchantSubmissionDetail', { merchant_detail_id: merchantDetailId }).then(res => {
        if (res.data.response_code === 2100) {
          this.$swal.fire({
            title: 'Successfully Approved',
            text: 'Merchant details have been successfully approved',
            icon: 'success',
          })
        } else {
          this.$swal.fire({
            title: `Failed ${res.data.response_code}`,
            text: res.data.description,
            icon: 'error',
          })
        }

        this.refreshTable()
      })
    },
    async registerNewMerchant() {
      await this.$refs.onboardNewMerchantFormRef.registerNewMerchant()
    },
    async onRegisterNewMerchant(e) {
      if (e) {
        this.refreshTable()
        this.$refs.onboardNewMerchant.hide()
      }
    },
    async resetRejectSubmission() {
      this.rejectForm.rejected_onboarding_checklists = []
      this.rejectForm.reason = ''
    },
    async rejectSubmission(data) {
      const payload = {
        merchant_detail_id: data.merchant_detail_id,
        rejected_onboarding_checklists: this.rejectForm.rejected_onboarding_checklists.toString(),
        reason: this.rejectForm.reason,
      }

      if (this.rejectSection === 'details') {
        await this.$store.dispatch('onboardingv2/onboardingRejectMerchantSubmissionDetail', payload).then(res => {
          if (res.data.response_code === 2100) {
            this.$swal.fire({
              title: 'Successfully Rejected',
              text: 'Merchant detail have been successfully rejected',
              icon: 'success',
            })
          } else {
            this.$swal.fire({
              title: `Failed ${res.data.response_code}`,
              text: res.data.description,
              icon: 'error',
            })
          }

          this.resetRejectSubmission()
          this.refreshTable()
        })
      }
      if (this.rejectSection === 'agreement') {
        await this.$store.dispatch('onboardingv2/onboardingRejectMerchantAgreement', payload).then(res => {
          if (res.data.response_code === 2100) {
            this.$swal.fire({
              title: 'Successfully Rejected',
              text: 'Merchant agreement have been successfully rejected',
              icon: 'success',
            })
          } else {
            this.$swal.fire({
              title: `Failed ${res.data.response_code}`,
              text: res.data.description,
              icon: 'error',
            })
          }

          this.resetRejectSubmission()
          this.refreshTable()
        })
      }

      console.log(payload)
    },
    async approveMerchantAgreement(merchantDetailId) {
      await this.$store.dispatch('onboardingv2/onboardingApproveMerchant', { merchant_detail_id: merchantDetailId }).then(res => {
        if (res.data.response_code === 2100) {
          this.$swal.fire({
            title: 'Successfully Approved',
            text: 'Merchant Agreement have been successfully approved.',
            icon: 'success',
          })
        } else {
          this.$swal.fire({
            title: `Failed ${res.data.response_code}`,
            text: res.data.description,
            icon: 'error',
          })
        }

        this.refreshTable()
      })
    },
  },
}
</script>

<style lang="scss">

.rejection-reason-checkbox {
  .custom-control.custom-checkbox {
    width: 100%;
  }
}

</style>
